<script lang="ts">
  import { buttonSizeClass, type ButtonSize } from '$lib/button-styles'
  import Spinner from '$lib/components/Spinner.svelte'

  export let label = null
  export let size: ButtonSize = 'md'
  export let priority: 'primary' | 'secondary' | 'tertiary' = 'primary'
  export let theme: 'brand' | 'dark' = 'brand'
  export let loading = false
  export let href: string
  let className = ''
  export { className as class }
  let sizeClass = buttonSizeClass(size)

  const { className: _, ...props } = $$restProps

  $: priorityClasses =
    priority == 'primary'
      ? 'tracking-wide font-semibold'
      : priority == 'secondary'
      ? 'normal-case text-xl font-semibold tracking-wide'
      : ''
  $: themeClasses =
    theme == 'brand'
      ? 'from-rose-600 active:to-amber-600 focus:from-rose-600 focus:to-amber-600 bg-gradient-to-tr to-brand-mid-gradient text-white active:text-white disabled:bg-grey-md disabled:text-black disabled:from-transparent disabled:to-transparent disabled:cursor-not-allowed'
      : theme == 'dark'
      ? 'hover:bg-gray-700 bg-black text-white active:text-white disabled:bg-grey-main disabled:text-gray-900 active:bg-gray-800'
      : ''
</script>

<a
  {href}
  {...props}
  class={`flex
          flex-row
          items-center
          justify-center
          appearance-none
          transition-all
          duration-75
          hover:opacity-90
          text-center
          rounded-full
          whitespace-nowrap
          leading-4
          focus:outline-none
          ${priorityClasses}
          ${themeClasses}
          ${sizeClass}
          ${className}
          `}
  on:click
>
  {#if loading}
    <span class="pr-2 -my-1">
      <Spinner {size} embedded />
    </span>
  {/if}
  <slot>{label}</slot>
</a>
